<template>
	<div class="main-content">
		<ktv-breadcrumb
			title="Transaction List"
			page="Transaction"
			folder="Transaction"
		/>
		<ktv-table
			id="transactionlist"
			:columns="columns"
			:is-loading="isLoading"
			:rows="rows"
			:total-rows="totalRecords"
			:mode="modeTable"
			:filter="true"
			search-placeholder="Search by Real Name"
			@on-open-filter="openFilter()"
			@on-column-filter="onColumnFilter"
			@on-page-change="onPageChange"
			@on-per-page-change="onPerPageChange"
			@on-search="onSearchFilter"
			@on-sort-change="onSortChange"
		>
			<template #actionleft>
				<ktv-button text="Download Template" icon="download" color="green-light" @click="openmodalSME()" />&nbsp;&nbsp;
				<ktv-button text="Import Transaction" icon="add" color="green-light" @click="openmodalUpload()" />
			</template>
			<template #rows="{ props }">
				<span v-if="props.column.field == 'button' && props.row.PaymentMethodID">
					<b-dropdown
						size="xs"
						variant="link"
						toggle-class="text-decoration-none"
						no-caret
					>
						<template #button-content>
							<span class="btndropdownaction">
								<img :src="icondropdown">
							</span>
						</template>
						<b-dropdown-item class="dropdownitem-action" @click="openForm('View', props.row)">
							<img :src="iconview" class="imgicon"> View
						</b-dropdown-item>
					</b-dropdown>
				</span>
			</template>
		</ktv-table>

		<ktv-modal
			v-model="modalsFilter"
			title="Filter"
			size="lg"
			@close="modalsFilter = false"
		>
			<template #content>
				<b-form>
					<div class="form-group row mt-3">
						<label class="col-sm-2 col-form-label">Transacation Date</label>
						<div class="col-sm-4">
							<b-form-input
								v-model="filter.fromDate"
								type="date"
								class="form-control"
							/>
						</div>
						<div class="text-center mt-2" style="width:10px">
							-
						</div>
						<div class="col-sm-4">
							<input
								v-model="filter.toDate"
								type="date"
								class="form-control"
							>
						</div>
					</div>
					<div class="form-group row mt-3">
						<label class="col-sm-2 col-form-label">Partner</label>
						<div class="col-sm-4">
							<b-form-select
								v-model="filter.partnerID"
								:options="optionPartner"
							/>
						</div>
					</div>
				</b-form>
			</template>
			<template #footer>
				<div align="right">
					<ktv-button
						text="Apply Filter"
						color="light"
						icon="filter"
						style="margin-right: 5px"
						@click="applyFilter()"
					/>

					<ktv-button
						text="Close"
						color="gray-light"
						style="margin-right: 5px"
						@click="modalsFilter = false"
					/>
				</div>
			</template>
		</ktv-modal>

		<!-- MODAL ADD SME-->
		<ktv-modal
			v-model="modalsSME"
			title="Choose SME"
			size="lg"
			@close="modalsSME = false"
		>
			<template #content>
				<b-form>
					<div class="form-group row mt-3">
						<label class="col-sm-2 col-form-label">SME</label>
						<div class="col-sm-10">
							<v-select
								v-model="smelist"
								v-validate="'required'"
								:options="smedatas"
								:state="validateState('sme')"
								name="sme"
							/>
							<b-form-invalid-feedback>{{ veeErrors.first("sme") }}</b-form-invalid-feedback>
						</div>
					</div>
				</b-form>
				<b-form-group id="fieldset-1" disabled description="">
					<b-form-input id="input-1" v-model="Name" hidden></b-form-input>
					<b-form-input id="input-1" v-model="Email" hidden></b-form-input>
				</b-form-group>
			</template>
			<template #footer>
				<div align="right">
					<ktv-button
						text="Download"
						color="light"
						style="margin-right: 5px"
						icon="download"
						@click="getProduct"
					/>
				</div>
			</template>
		</ktv-modal>

		<!-- MODAL ADD Transaction-->
		<ktv-modal
			v-model="modalsUpload"
			title="Upload File Transaction"
			size="lg"
			@close="modalsUpload = false"
		>
			<template #content>
				<b-form>
					<b-file
						ref="filexlsx"
						v-validate="'required'"
						size="sm"
						accept=".xlsx"
						:placeholder="`Choose a file ..`"
						:state="validateState('file')"
						name="file"
						type="file"
						@change="onChange"

						@click.native="resetFile()"
						@accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
					/>
					<small class="text-danger">
						{{ veeErrors.first("file") }}
					</small>
				</b-form>
			</template>
			<template #footer>
				<div align="right">
					<ktv-button
						style="margin-right: 5px"
						class="mt-3"
						text="Cancel"
						icon="close"
						color="light"
						@click="modalsUpload = false"
					/>
					<ktv-button
						class="mt-3"
						text="Upoad FIle"
						icon="save"
						color="primary"
						@click="storeFile"
					/>
				</div>
			</template>
		</ktv-modal>

		<!-- MODAL UPLOAD FAILED-->
		<ktv-modal
			v-model="modalsUploadFailed"
			title="List of Failed Transaction Uploads"
			size="lg"
			@close="modalsUploadFailed = false"
		>
			<template #content>
				<table class="table table-hover ">
					<thead class="thead">
						<tr class="">
							<th>No</th>
							<th>Product UID</th>
							<th>Reason</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(a, key) in customer" :key="key">
							<td>{{ a.No }}</td>
							<td>{{ a.ProductUID }}</td>
							<td>{{ a.Reason }}</td>
						</tr>
					</tbody>
					<tbody>
						<tr v-for="(a, key) in product" :key="key">
							<td>{{ a.No }}</td>
							<td>{{ a.ProductUID }}</td>
							<td>{{ a.Reason }}</td>
						</tr>
					</tbody>
					<tbody>
						<tr v-for="(a, key) in quantity" :key="key">
							<td>{{ a.No }}</td>
							<td>{{ a.ProductUID }}</td>
							<td>{{ a.Reason }}</td>
						</tr>
					</tbody>
				</table>
			</template>
			<template #footer>
				<div align="right">
					<ktv-button
						style="margin-right: 5px"
						class="mt-3"
						text="Close"
						icon="close"
						color="gray-light"
						@click="modalsUploadFailed = false"
					/>
				</div>
			</template>
		</ktv-modal>
	</div>
</template>
<script>
	import { KtvTable, KtvModal, KtvButton } from "@/components"
	import { mapActions, mapGetters } from 'vuex'
	import * as XLSX from 'xlsx'

	export default {
		metaInfo: {
			title: "Transaction List",
		},
		components: { KtvTable, KtvModal, KtvButton }, XLSX,
		data() {
			return {
				/* eslint-disable global-require */
				iconview: require("@/assets/images/icon/iconview.png"),
				icondropdown: require("@/assets/images/icon/icondropdown.png"),
				/* eslint-disable global-require */
				isLoading: false,
				modeTable: "remote",
				columns: [
					{
						label: "Action",
						field: "button",
						html: true,
						tdClass: "text-center",
						thClass: "text-center",
					},
					{
						label: "Transaction Code",
						field: "TransactionCode",
						thClass: "text-left",
					},
					{
						label: "Date",
						field: "TransactionDate",
						thClass: "text-left",
					},
					{
						label: "SME Name",
						field: "SmeName",
						thClass: "text-left",
					},
					// {
					// 	label: "Buyer Name",
					// 	field: "BuyerName",
					// 	thClass: "text-left",
					// },
					{
						label: "Total Amount",
						field: "PriceTotal",
						thClass: "text-left",
					}
				],
				rows: [],
				totalRecords: 0,
				serverParams: {
					columnFilters: {
						name: "",
						fromDate: "",
						toDate: "",
						partnerID: "",
					},
					sort: [{
						field: "",
						type: ""
					}],
					currentPage: 1,
					rowPerpage: 10,
				},
				modalsFilter: false,
				filter: {
					name: '',
					fromDate: null,
					toDate: null,
					partnerID: '',
				},
				modalsSME: false,
				smedatas:[],
				smelist:null,
				paramSME: {
					EntityID: "",
					Name: "",
					Email: "",
				},
				Email:"",
				Name:"",
				Datas: {
					// We will make a Workbook contains 2 Worksheets
					'data_transaction': [
						{
							No: "",
							Date: "",
							Name: "",
							ProductUID: "",
							Qty: "",
							Kiosk: "kiosk.test.uat@getnada.com",
						},
						{
							No: "",
							Date: "",
							Name: "",
							ProductUID: "",
							Qty: "",
							Kiosk: "kiosk.test.uat@getnada.com",
						},
					],
					'data_product': []
				},
				productData:[],
				transactionData:[],
				modalsUpload: false,
				file: null,
				excelData:[],
				data:null,
				modalsUploadFailed: false,
				product:[],
				quantity:[],
				customer:[],
			}
		},
		computed: {
			...mapGetters({
				optionPartner: "MASTER/getPartner",
				optionProdCateg: "MASTER/getProdCateg",
				optionPayMethod: "MASTER/getPayMethod",
			})
		},
		watch: {
			smelist: {
				immediate: true,
				handler: function (value) {
					if (value) {
						this.getemail()
					}
				}
			}

		},
		mounted() {
			// this.loadItems()
			this.loadOption();
			this.getSME();
			this.getProduct();
		},
		methods: {
			...mapActions("MASTER", ["actGetPartner", "actGetProdCateg", "actGetPayMethod"]),
			...mapActions("TRANSACTION", ["pushData"]),
			loadOption() {
				this.actGetPartner();
				this.actGetProdCateg();
				this.actGetPayMethod();
			},
			applyFilter() {
				this.onColumnFilter(this.filter);
				this.loadItems()
				this.modalsFilter = false
			},
			openFilter() {
				this.modalsFilter = true
				this.filter.fromDate = ""
				this.filter.toDate = ""
				this.filter.partnerID = ""
			},
			openForm(opsi, items = []) {
				let data = {
					opsiDisplay: opsi,
					transactionCode: items.TransactionCode,
				}
				this.pushData(data);
				this.$router.push('/transaction/mainform')
			},
			updateParams(newProps) {
				this.serverParams = Object.assign({}, this.serverParams, newProps)
			},
			onPageChange(params) {
				this.updateParams({ currentPage: params.currentPage })
				this.loadItems()
			},
			onPerPageChange(params) {
				this.updateParams({ rowPerpage: params.currentPerPage })
				this.loadItems()
			},
			onSortChange(params) {
				this.updateParams({
					sort: [
						{
							type: params[0].type,
							field: params[0].field,
						},
					],
					currentPage: 1
				})
				this.loadItems()
			},
			onColumnFilter(params) {
				this.updateParams({
					columnFilters: params,
				})
				this.loadItems()
			},
			onSearchFilter(event) {
				this.updateParams({
					columnFilters: { UserRealName: event },
				})
				this.loadItems()
			},
			resetFile() {
				this.$refs.filexlsx.reset();
			},
			loadItems() {
				this.isLoading = true
				this.$http
					.Post(this.serverParams, this.$urlApi.transaction.list)
					.then((response) => {
						this.isLoading = false
						if (response.success) {
							this.totalRecords = response.results.total
							this.rows = response.results.data
							if(Object.keys(this.rows).length < 10 && Object.keys(this.rows).length !== 0) {
								this.rows = response.results.data
								for(let i=0; Object.keys(this.rows).length<10; i++) {
									this.rows.push({});
								}
							}
						} else {
							this.$swal("Error!", response.error_massage, "error")
						}
					})
					.catch((error) => {
						this.isLoading = false
						this.$swal("Error!", error.response.error_massage, "error")
					})
			},
			openmodalSME() {
				this.modalsSME = true
				this.smelist = ""
			},
			getSME() {
				this.$http
					.Get({}, this.$urlApi.transaction.getSMElist)
					.then((r) => {
						this.smedatas = []
						r.results.forEach(async (value, index) => {
							this.smedatas.push({
								index: index,
								id: value.EntityID,
								label: value.Name,
								Name: value.Name,
								Email: value.Email,
							})
						})
					})
					.catch(() => {
						// this.$swal("Error!", e.response.error_message, "error")
					})
			},
			getemail() {
				this.transactionData = []
				let a = this.smedatas.find(o => o.id === this.smelist.id);
				this.Email = a.Email
				this.Name = a.Name
				this.transactionData.push({
					No: "",
					Date: "",
					Name: "",
					ProductUID: "",
					Qty: "",
					Kiosk: a.Email
				})
			},
			getProduct() {
				this.productData = []
				this.$http
					.Get({}, this.$urlApi.transaction.getProductTrans + '?Email='+ this.Email)
					.then((r) => {
						if (r.success) {
							r.results.data.forEach((e) => {
								this.productData.push({
									ProductID: e.ProductID,
									ProductUid: e.ProductUid,
									ProductName: e.Name,
									Stock: e.Stock,
								})
							})
						} else {
							// this.$swal("Error!", r.error_massage, "error")
						}
						this.exportToExcel()
						this.modalsSME = false
						this.smelist = ""
					})
					.catch(() => {
						// this.$swal("Error!", e.response.data.error_message, "error")
					})
			},
			exportToExcel() {

				var dataTransactionWS = XLSX.utils.json_to_sheet(this.transactionData)
				var dataProductWS = XLSX.utils.json_to_sheet(this.productData)

				var wb = XLSX.utils.book_new()

				XLSX.utils.book_append_sheet(wb, dataTransactionWS, 'data_trasaction') // sheetAName is name of Worksheet
				XLSX.utils.book_append_sheet(wb, dataProductWS, 'data_product')

				const today = new Date();
				const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
				const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
				const dateTime = date +' '+ time;
				this.timestamp = dateTime;

				XLSX.writeFile(wb, 'Import Transaction_'+this.Name+'_'+this.timestamp+'.xlsx') // name of the file is 'book.xlsx'
			},
			openmodalUpload() {
				this.modalsUpload = true

			},
			onChange(event) {
				this.file = event.target.files ? event.target.files[0] : null;
				if (this.file) {
					const reader = new FileReader();

					reader.onload = (e) => {
						/* Parse data */
						const bstr = e.target.result;
						const wb = XLSX.read(bstr, { type: 'binary' });
						// eslint-disable-next-line no-unused-vars
						const filename = this.file[0];
						/* Get first worksheet */
						const wsname = wb.SheetNames[0];
						const ws = wb.Sheets[wsname];
						/* Convert array of arrays */
						this.data = XLSX.utils.sheet_to_json(ws, { header: 2 });
					}

					reader.readAsBinaryString(this.file);
				}
			},
			storeFile() {
				if(this.$refs.filexlsx.files.length === 0){
					this.$swal({
						type: 'error',
						title: 'File cannot be empty',
					})
					return
				}
				this.$swal.fire({
					title: 'Submiting...',
					allowOutsideClick: false,
					onBeforeOpen: () => {
						this.$swal.showLoading()
						let payload = {
							"Nama_File": this.file.name,
							"Data":
								this.data
						}
						this.$http
							.Post(payload, this.$urlApi.transaction.uploadFile)
							.then(async (response) => {
								if (response.results.product_error.length > 0 && response.results.quantity_error.length > 0 && response.results.customer_error.length > 0) {
									this.product = response.results.product_error
									this.quantity = response.results.quantity_error
									if(Object.keys(this.product).length < 10 && Object.keys(this.product).length !== 0 && Object.keys(this.quantity).length < 10 && Object.keys(this.quantity).length !== 0) {
										this.product = response.results.product_error
										this.quantity = response.results.quantity_error
										for(let i=0; Object.keys(this.product).length<10 && Object.keys(this.quantity).length<10; i++) {
											this.product.push({});
											this.quantity.push({});
										}
									}
									this.modalsUploadFailed = true
									await this.$swal({
										title: 'Error!',
										text: 'Upload File Failed',
										html:
											'Reason: <b>' + response.results.product_error[0].Reason + '</b>, <br>' +
											'<b>' + response.results.quantity_error[0].Reason + '</b> , <br>' +
											'<strong>' + response.results.customer_error[0].Reason + '</strong><br>' +
											'Row: <b>' +  + '<b>',
										type: 'error',
									});
									this.modalsUpload = false
									return
								}
								if (response.results.product_error.length > 0 && response.results.quantity_error.length > 0) {
									this.product = response.results.product_error
									for(let i=0; response.results.product_error.length>i; i++) {
										// console.log('No: ', response.results.product_error[i].No)
									}
									await this.$swal({
										title: 'Error!',
										text: 'Upload File Failed',
										html:
											'Reason: <b>' + response.results.product_error[0].Reason + '</b>, <br>' +
											'<b>' + response.results.quantity_error[0].Reason + '</b><br>'
										// '<small>No: 1, 2, 3, 4</small>' +
										// '<small v-for="item in' + response.results.product_error +'">' +
										// '  {{ item.No }}' +
										// '</small>'
										,
										type: 'error',
									});
									this.modalsUpload = false
									return
								}
								if (response.results.product_error.length > 0 && response.results.customer_error.length > 0) {
									await this.$swal({
										title: 'Error!',
										text: 'Upload File Failed',
										html:
											'Reason: <b>' + response.results.product_error[0].Reason + '</b>, <br>' +
											'<b>' + response.results.customer_error[0].Reason + '</b>',
										type: 'error',
									});
									this.modalsUpload = false
									return
								}
								if (response.results.quantity_error.length > 0 && response.results.customer_error.length > 0) {
									await this.$swal({
										title: 'Error!',
										text: 'Upload File Failed',
										html:
											'Reason: <b>' + response.results.quantity_error[0].Reason + '</b>, <br>' +
											'<b>' + response.results.customer_error[0].Reason + '</b>',
										type: 'error',
									});
									this.modalsUpload = false
									return
								}
								if (response.results.product_error.length > 0) {
									await this.$swal({
										title: 'Error!',
										text: 'Upload File Failed',
										html:
											'Reason: <b>' + response.results.product_error[0].Reason + '</b>',
										type: 'error',
									});
									this.modalsUpload = false
									return
								}
								if (response.results.quantity_error.length > 0) {
									await this.$swal({
										title: 'Error!',
										text: 'Upload File Failed',
										html:
											'Reason: <b>' + response.results.quantity_error[0].Reason + '</b>',
										type: 'error',
									});
									this.modalsUpload = false
									return
								}
								if (response.results.customer_error.length > 0) {
									await this.$swal({
										title: 'Error!',
										text: 'Upload File Failed',
										html:
											'Reason: <b>' + response.results.customer_error[0].Reason + '</b>',
										type: 'error',
									});
									this.modalsUpload = false
									return
								}

								if (response.success === true) {
									await this.$swal(
										'Saved!',
										'Successfully submit.',
										'success'
									);
									this.modalsUpload = false
								}
							})
							.catch(() => {
								this.$swal({
									type: 'error',
									title: 'Upload Failed',
								})
							});

					}
				})
			},
		},
	}
</script>
